@import url("https://fonts.googleapis.com/css2?family=Barlow&family=Cairo:wght@200;300;400;500;600;700;800;900;1000&display=swap");
* {
  font-family: "Cairo", sans-serif !important;
}
.header-container {
  padding: 1rem;
  background-color: #17b5da;
  border-radius: 0 0 10px 10px;
  position: relative;
    z-index: 10000;
}
.container {
  background-color: #035a9a;
  min-height: 100vh;
  padding-left: 0 !important;
  color: #fff;
  padding-right: 0 !important;
}
.slider-img {
  background: #00000052;
  width: 73%;
  border-radius: 50%;
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  height: 17rem;
  align-items: center;
}
.swiper-button-next,
.swiper-rtl .swiper-button-prev,
.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled,
.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  border: 1px solid;
  opacity: 1;
  color: #fff;
}
.swiper-button-next::after,
.swiper-rtl .swiper-button-prev::after,
.swiper-button-next:after,
.swiper-button-prev:after,
.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  font-size: 1.5rem !important;
}
.navbar-nav {
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
  padding-right: 1rem;
}
.navbar-nav a {
  text-decoration: none !important;
}
.btn {
  margin-top: 1rem;
  padding: 0.5rem;
  background-color: #17b5da;
  border-radius: 25px;
  width: 80%;
  margin-left: 2rem;
}
.icon {
  font-size: 1.5rem;
  color: #fff;
}
.logo {
  width: 2rem;
}
.icon-container {
  margin-bottom: 1rem;
}

@media (max-width: 950px) {
    .ul-responsive{
           margin-top: 5rem;
    }
    .slider-img {
    width: 70%;
    height: 35rem;
  }
  li > a {
    font-size: 2.5rem;
  }
  .slider-img img {
    height: 30rem !important;
  }
  .swiper-button-next,
  .swiper-rtl .swiper-button-prev,
  .swiper-button-next.swiper-button-disabled,
  .swiper-button-prev.swiper-button-disabled,
  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    height: 5rem;
    width: 5rem;
  }
  .swiper-button-next,
  .swiper-button-prev {
    top: 40%;
  }
  .slider-title {
    margin-top: 3rem !important;
    font-size: 40px !important;
  }
  .slider-description {
    font-size: 30px !important;
    width: 40rem !important;
  }
  .btn {
    margin-left: 5rem !important;
    font-size: 1.5rem !important;
    margin-top: 4rem !important;
    padding: 1.5rem !important;
  }
  .icon-title {
    font-size: 2rem;
  }
  .icon {
    font-size: 3.5rem;
  }
  .logo {
    width: 4rem;
  }
  .menu {
    font-size: 4rem !important;
  }
  .swiper {
    margin-top: -1rem;
  }
  .icon-container {
    margin-top: 3rem !important;
  }
}
@media (max-width: 900px) {
  .slider-img {
    width: 70%;
    height: 35rem;
  }
  .ul-responsive{
    margin-top: 5rem;
}
  li > a {
    font-size: 2rem;
  }
  .slider-img img {
    height: 30rem !important;
  }
  .swiper-button-next,
  .swiper-rtl .swiper-button-prev,
  .swiper-button-next.swiper-button-disabled,
  .swiper-button-prev.swiper-button-disabled,
  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    height: 5rem;
    width: 5rem;
  }
  .swiper-button-next,
  .swiper-button-prev {
    top: 40%;
  }
  .slider-title {
    margin-top: 2rem !important;
    font-size: 40px !important;
  }
  .slider-description {
    font-size: 25px !important;
    width: 40rem !important;
  }
  .ul-responsive{
    color: #fff !important;
  }
  .btn {
    margin-left: 5rem !important;
    font-size: 1.5rem !important;
    margin-top: 1rem !important;
    padding: 1.5rem !important;
  }
  .icon-title {
    font-size: 2rem;
  }
  .icon {
    font-size: 2.5rem;
  }
  .logo {
    width: 3rem;
  }
  .menu {
    font-size: 3rem !important;
  }
  .swiper {
    margin-top: -1rem;
  }
  .icon-container{
    margin-top: 1rem !important;
    margin-bottom: 0;
    padding-bottom: 1rem;
  }
}
@media (max-width: 800px) {
    .slider-img {
        width: 56%;
        height: 25rem;
    }
    .slider-img img {
      height: 23rem !important;
    }
    .swiper-button-next,
    .swiper-rtl .swiper-button-prev,
    .swiper-button-next.swiper-button-disabled,
    .swiper-button-prev.swiper-button-disabled,
    .swiper-button-prev,
    .swiper-rtl .swiper-button-next {
      height: 4rem;
      width: 4rem;
    }
    .swiper-button-next,
    .swiper-button-prev {
      top: 40%;
    }
    .slider-title {
      margin-top: 1rem !important;
      font-size: 35px !important;
    }
    .slider-description {
      font-size: 24px !important;
      width: 40rem !important;
    }
    .btn {
      margin-left: 5rem !important;
      font-size: 1.5rem !important;
      margin-top: 1rem !important;
      padding: 1.5rem !important;
    }
    .icon-title {
      font-size: 2rem;
    }
    .icon {
      font-size: 2.5rem;
    }
    .logo {
      width: 3rem;
    }
    .menu {
      font-size: 3rem !important;
    }
    .swiper {
      margin-top: -1rem;
    }
    .icon-container{
      margin-top: 1rem !important;
      margin-bottom: 0;
      padding-bottom: 1rem;
    }
  }
  @media (max-width: 600px) {
    .ul-responsive{
        margin-top: 4rem;
 }
    .slider-img {
        width: 56%;
        height: 18rem;
    }
    .slider-img img {
      height: 30rem !important;
    }
    .swiper-button-next,
    .swiper-rtl .swiper-button-prev,
    .swiper-button-next.swiper-button-disabled,
    .swiper-button-prev.swiper-button-disabled,
    .swiper-button-prev,
    .swiper-rtl .swiper-button-next {
      height: 3rem;
      width: 3rem;
    }
    .swiper-button-next,
    .swiper-button-prev {
      top: 40%;
    }
    .slider-title {
      margin-top: 1rem !important;
      font-size: 24px !important;
    }
    .slider-description {
      font-size: 16px !important;
      width: 25rem !important;
    }
    .btn {
      margin-left: 3.3rem !important;
      font-size: 1rem !important;
      margin-top: 1rem !important;
      padding: 1rem !important;
    }
    .icon-title {
      font-size: 1rem;
    }
    .icon {
      font-size: 1.5rem;
    }
    .logo {
      width: 2rem;
    }
    .menu {
      font-size: 2rem !important;
    }
    .swiper {
      margin-top: -1rem;
    }
    .icon-container{
      margin-top: 1rem !important;
      margin-bottom: 0;
      padding-bottom: 1rem;
    }
  }
  
  @media (max-width: 500px) {
    .slider-img {
        margin-top: 4rem !important;
        width: 65%;
        height: 16rem;
    }
    .slider-img img {
      height: 15rem !important;
    }
    .swiper-button-next,
    .swiper-rtl .swiper-button-prev,
    .swiper-button-next.swiper-button-disabled,
    .swiper-button-prev.swiper-button-disabled,
    .swiper-button-prev,
    .swiper-rtl .swiper-button-next {
      height: 3rem;
      width: 3rem;
    }
    .swiper-button-next,
    .swiper-button-prev {
      top: 40%;
    }
    .slider-title {
      margin-top: 2rem !important;
      font-size: 30px !important;
    }
    .slider-description {
      font-size: 20px !important;
      width: 22rem !important;
    }
    .btn {
      margin-left: 2.5rem !important;
      font-size: 1.5rem !important;
      margin-top: 1rem !important;
      padding: 1rem !important;
    }
    .icon-title {
      font-size: 1.5rem;
    }
    .icon {
      font-size: 2rem;
    }
    .logo {
      width: 2rem;
    }
    .menu {
      font-size: 2rem !important;
    }
    .swiper {
      margin-top: -1rem;
    }
    .icon-container{
      margin-top: 2rem !important;
      margin-bottom: 0;
      padding-bottom: 1rem;
    }
    .icon-container:first-child {
        margin-bottom: 1rem;
    }
  }
  
  @media (max-width: 400px) {
    .slider-img {
        margin-top: 2rem !important;
        width: 65%;
        height: 16rem;
    }
    li > a {
        font-size: 1.5rem;
      }
    .slider-img img {
      height: 15rem !important;
    }
    .swiper-button-next,
    .swiper-rtl .swiper-button-prev,
    .swiper-button-next.swiper-button-disabled,
    .swiper-button-prev.swiper-button-disabled,
    .swiper-button-prev,
    .swiper-rtl .swiper-button-next {
      height: 3rem;
      width: 3rem;
    }
    .swiper-button-next,
    .swiper-button-prev {
      top: 40%;
    }
    .slider-title {
      margin-top: 2rem !important;
      font-size: 25px !important;
    }
    .slider-description {
      font-size: 18px !important;
      width: 22rem !important;
    }
    .btn {
      margin-left: 2.5rem !important;
      font-size: 1.5rem !important;
      margin-top: 2rem !important;
      padding: 1rem !important;
    }
    .icon-title {
      font-size: 1.5rem;
    }
    .icon {
      font-size: 2rem;
    }
    .logo {
      width: 2rem;
    }
    .menu {
      font-size: 2rem !important;
    }
    .swiper {
      margin-top: -1rem;
    }
    .icon-container{
      margin-top: 1rem !important;
      margin-bottom: 0;
      padding-bottom: 1rem;
    }
    .icon-container:first-child {
        margin-bottom: 1rem;
    }
  }
  
  @media (max-width: 380px) {
    .slider-img {
        margin-top: 2rem !important;
        width: 65%;
        height: 15rem;
    }
    li > a {
        font-size: 1rem;
      }
    .slider-img img {
      height: 13rem !important;
    }
    .swiper-button-next,
    .swiper-rtl .swiper-button-prev,
    .swiper-button-next.swiper-button-disabled,
    .swiper-button-prev.swiper-button-disabled,
    .swiper-button-prev,
    .swiper-rtl .swiper-button-next {
      height: 2rem;
      width: 2rem;
    }
    .swiper-button-next,
    .swiper-button-prev {
      top: 45%;
    }
    .slider-title {
      margin-top: 1rem !important;
      font-size: 20px !important;
    }
    .slider-description {
      font-size: 12px !important;
      width: 18rem !important;
    }
    .btn {
      margin-left: 2.5rem !important;
      font-size: 1rem !important;
      margin-top: 1rem !important;
      padding: 1rem !important;
    }
    .icon-title {
      font-size: 1rem;
    }
    .icon {
      font-size: 1rem;
    }
    .logo {
      width: 1.5rem;
    }
    .menu {
      font-size: 1.5rem !important;
    }
    .swiper {
      margin-top: -1rem;
    }
    .icon-container{
      margin-top: 2rem !important;
      margin-bottom: 0;
      padding-bottom: 1rem;
    }
    .icon-container:first-child {
        margin-bottom: 1rem;
    }
  }
  